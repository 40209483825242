import "./style.css"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Mentions = () => {
    return <div className="page">


        <Header />
        <div className="container">
            <h1>
                Mentions Légales
                <br />
                <i>En vigueur au 22/06/2023</i>
            </h1>
            <section>
                Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
                Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et
                visiteurs, ci-après l""Utilisateur", du site www.weddiup.com , ci-après le "Site", les présentes
                mentions légales.
                La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve
                des présentes mentions légales.
                Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
            </section>
            <section>
                <h2>ARTICLE 1 - L'EDITEUR</h2>
                L'édition du Site est assurée par Société Techer Digital EURL au capital de 100 euros, immatriculée
                au Registre du Commerce et des Sociétés de Saint Brieuc sous le numéro 902445915 dont le siège
                social est situé au 5 rue de la Croix au Chat,
                Numéro de téléphone 0651228429,
                Adresse e-mail : contact@techer.digital.
                N° de TVA intracommunautaire : FR51902445915
                Le Directeur de la publication est Jean Sébastien TECHER
                ci-après l'"Editeur".
            </section>
            <section>
                <h2>ARTICLE 2 - L'HEBERGEUR</h2>
                L'hébergeur du Site est la société SCALEWAY SAS, dont le siège social est situé au BP 438 75366
                PARIS CEDEX 08 FRANCE
                contact
            </section>
            <section>
                <h2>ARTICLE 3 - ACCES AU SITE</h2>
                Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption
                programmée ou non et pouvant découlant d’une nécessité de maintenance.
                En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
            </section>
            <section>
                <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
                    Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect
                    de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
                    et aux libertés.
                    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit
                    d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur
                    exerce ce droit :
                    · via son espace personnel ;
                    Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site,
                    sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires
                    telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
            </section>
        </div>
        <Footer />

    </div>
}

export default Mentions 