import i18n from "../../translations";
import { Routes, Route, useParams } from 'react-router-dom';
import Button from "../Button";
import "./style.css";

const Block = (props) => {
  const {
    heading,
    textBody,
    illustration,
    display,
    marginSetting,
    imgPositionSetting,
  } = props;

  const { t } = i18n;
  const { lang = "en" } = useParams()
  return (
    <div className={`block-container ${display}`}>
      <div className={`block-text ${marginSetting}`}>
        <h2 className="heading">{heading}</h2>
        <p className="text-body">{textBody}</p>
        <div className="btn">
          <a
            className="button"
            href={`https://app.weddiup.com/${lang}/authentication/register`}
          >
            {t("register")}
          </a>
          <a
            className="outLink"
            href={`https://app.weddiup.com/${lang}/authentication/login`}
          >
            {t("login")}
          </a>
        </div>
      </div>
      <div className="block-img">
        <img
          className={`illustration ${imgPositionSetting} `}
          src={illustration}
          alt=""
        />
      </div>
    </div>
  );
};

export default Block;
