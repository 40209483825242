
import './App.css';
import Home from './Home';
import {
  Route,
  Routes
} from "react-router-dom";
import Pricing from './Pricing';
import Mentions from './Mentions';
import { Features } from './Features';


function App() {
  return (
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/features' element={<Features />}></Route>
          <Route path='/legal' element={<Mentions />}></Route>
          <Route path='/:lang' element={<Home />}></Route>
          <Route path='/:lang/legal' element={<Mentions />}></Route>
          <Route path='/:lang/features' element={<Features />}></Route>
        </Routes>
      </div>
  );
}

export default App;
