import "./style.css"
import Header from "../components/Header"
import Block from "../components/Block"
import Couple from "../assets/Couple.svg"
import Groupe from "../assets/Groupe.svg"
import Download from "../assets/Download.svg"
import App from "../assets/App.svg"
import SmallBlock from "../components/SmallBlock"
import Footer from "../components/Footer"
import i18n from "../translations"

const Home = () => {
    const { t } = i18n
    return <div className="page">
        <Header />
        <div className="block-box">
            <Block
                heading={t("catch_the_moment")}
                textBody={t("catch_the_moment_text")}
                illustration={Couple} />

            <Block
                heading={t("all_party_photos")}
                textBody={t("all_party_photos_text")}
                illustration={Groupe}
                display="block-container-reverse"
                marginSetting="margin-setting"
                imgPositionSetting="img-position-setting" />

            <div className="small-blocks">
                <SmallBlock
                    heading={t("mobile_app")}
                    textBody={t("mobile_app_text")}
                    illustration={App} />
                <SmallBlock
                    heading={t("group_photos")}
                    textBody={t(t("group_photos_text"))}
                    illustration={Download}
                    itemsBlockSetting="items-block-setting" />
            </div>
        </div>
        <Footer />

    </div>
}

export default Home 