import { Link } from "react-router-dom";
import { Routes, Route, useParams } from 'react-router-dom';
import Logo from "../../assets/Logo.svg";
import "./style.css";
import i18n from "../../translations";

const Header = () => {
  const { t } = i18n;
  const { lang = "en" } = useParams()
  return (
    <div className="header">
      <div className="logo-container">
        <img className="logo" src={Logo} alt="" />
        <p className="title">Weddi'</p>
        <strong>UP</strong>
      </div>
      <nav className="navigation">
        <ul className="nav-items">
          <li className="nav-item home">
            <Link to={`/${lang}`}>{t("home")}</Link>
          </li>
          <li className="nav-item">
            <Link to={`/${lang}/features`}>{t("features")}</Link>
          </li>
        </ul>
        <div>
            <a className="outLink" href="/fr">
                🇫🇷
            </a>
            <a className="outLink" href="/en">
                🇬🇧
            </a>
            <a className="outLink" href="/es">
                🇪🇸
            </a>
            <a className="outLink" href="/de">
                🇩🇪
            </a>
        </div>
        <a
          className="button"
          href={`https://app.weddiup.com/${lang}/authentication/register`}
        >
          {t("register")}
        </a>
      </nav>
    </div>
  );
};

export default Header;
