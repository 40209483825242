import "./style.css"
import { useParams } from 'react-router-dom';
import Apple from "../../assets/Apple.svg"
import Androïd from "../../assets/Android.svg"
import i18n from "../../translations"

const Footer = () => {
    const {t} = i18n;
    const { lang } = useParams()
    return <div className="footer-container">
        <div className="footer">
            <div className="box-app">
                <span className="intro">{t("Available soon on")}</span>

                <div className="app-box">
                    <div className="apple-box">
                        <img src={Apple} alt="" />
                        <p className="text-style">Apple</p>

                    </div>
                    <div className="android-box">
                        <img src={Androïd} alt="" />
                        <p className="text-style">Androïd</p>
                    </div>
                </div>
            </div>
            <div className="link">
                <ul>
                    <li><a href={`/${lang}/pricing`} >{t("pricing")}</a></li>
                    <li><a href={`/${lang}/cgu.pdf`} rel="noreferrer" target="_blank">{t("conditions")}</a></li>
                    <li><a href={`/${lang}/legal`} rel="noreferrer" target="_blank">{t("legal")}</a></li>
                </ul>
            </div>
            <div className="generality">
                <div className="generality-box">
                    <p className="presentation">
                        {t("footer_text")}
                    </p><br />

                    <a className="dedicate" href="https://www.techer.digital">Made with ❤️ by Techer Digital </a>
                </div>
            </div>
        </div>
    </div>
}

export default Footer