
import { Routes, Route, useParams } from 'react-router-dom';
import Footer from "../components/Footer";
import Header from "../components/Header";
import i18n from "../translations";

import "./style.css";
const { t } = i18n;

export const Features = () => {
    const { lang = "en" } = useParams()
    return  <div className="features-page">
    <Header />
    <section className="features-introduction">
        <h1 className="features-title">{t("offers")}</h1>
        {t("features-description")}
    </section>
    <section className="features-details">
    <table className="features-table">
        <thead>
            <tr>
                <th className="feature-title">{t("features")}</th>
                <th className="feature">{t("free")}</th>
                <th className="feature">{t("premium")}</th>
                <th className="feature">{t("gold")}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="feature-title">🔐 {t("personal_space")}</td>
                <td className="feature">✅</td>
                <td className="feature">✅</td>
                <td className="feature">✅</td>
            </tr>
            <tr>
                <td className="feature-title">📆 {t("availability")}</td>
                <td className="feature">1 an</td>
                <td className="feature">1 an</td>
                <td className="feature">1 an</td>
            </tr>
            <tr>
                <td className="feature-title">💾 {t("picture_storage")}</td>
                <td className="feature">10Go</td>
                <td className="feature">100Go</td>
                <td className="feature">1To</td>
            </tr>
            <tr>
                <td className="feature-title">😃 {t("guests")}</td>
                <td className="feature">10 {t("guests")}</td>
                <td className="feature">250 {t("guests")}</td>
                <td className="feature">1000 {t("guests")}</td>
            </tr>
            <tr>
                <td className="feature-title">📔 {t("create_album")}</td>
                <td className="feature">-</td>
                <td className="feature">-</td>
                <td className="feature">✅</td>
            </tr>
            <tr>
                <td className="feature-title">🌍 {t("personal_website")}</td>
                <td className="feature">-</td>
                <td className="feature">-</td>
                <td className="feature">✅</td>
            </tr>

            <tr>
                <td className="feature-title">💰 {t("price")}</td>
                <td className="feature price">{t("free")}</td>
                <td className="feature price">69 €</td>
                <td className="feature price disabled">289 €*</td>
            </tr>
        </tbody>
    </table>
    </section>

    <span className="features-details-disclaimer">* {t("open_for_summer")}.</span>

    <section className="features-start-now">
        <h2 className="features-start-now-title">{t("start_now")}</h2>
        <a className="button" href={`https://app.weddiup.com/${lang}/authentication/register`}>{t("registering")}</a>
    </section>

    <Footer />
</div> 
};
