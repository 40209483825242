import "./style.css";
import { Routes, Route, useParams } from 'react-router-dom';
import Button from "../Button";
import i18n from "../../translations";

const SmallBlock = (props) => {
    const { t } = i18n
    const { lang = "en" } = useParams()

  const { heading, textBody, illustration, itemsBlockSetting } = props;
  return (
    <div className={`Small-block-container ${itemsBlockSetting}`}>
      <div className="items-block">
        <h2 className="small-heading">{heading}</h2>
        <div className="img-box">
          <img className="small-illustration" src={illustration} alt="" />
        </div>
        <p className="small-text-body">{textBody}</p>
        <div className="btn">
          <a
            className="button"
            href={`https://app.weddiup.com/${lang}/authentication/register`}
          >
            { t("register") }
          </a>
          <a
            className="outLink"
            href={`https://app.weddiup.com/${lang}/authentication/login`}
          >
            { t("login") }
          </a>
        </div>
      </div>
    </div>
  );
};

export default SmallBlock;
